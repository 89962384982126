<template>
  <div :id="className" :style="{ width: width, height: height }"></div>
</template>

<script>
export default {
  props: {
    //播放器类名
    className: {
      type: String,
      default: 'videoPlay',
    },
    //播放器宽度
    width: {
      type: String,
      default: "100%"
    },
    //播放器高度
    height: {
      type: String,
      default: "100%"
    },
    //播放器封面图
    posterImg: {
      type: String,
      default: "",
    },
    //播放地址
    videoSrc: {
      type: String,
      default: "",
    },
    //是否播放
    videoAutoplay: {
      type: Boolean,
      default: false,
    },
    //是否直播
    live: {
      type: Boolean,
      default: false,
    },
    //直播是否播放
    liveAutoplay: {
      type: Boolean,
      default: false,
    },
  },
  name: 'ckplayer',
  data() {
    return {
      //播放器对象
      player: null,
      //播放器配置
      videoObject: {
        container: '#' + this.className, //容器的ID或className
        variable: 'player', // 播放函数名称
        volume: 0.8, // 音量，范围：0-1
        seek: 0, // 默认跳转的时间
        loop: false, // 播放结束是否循环播放
        debug: false, // 是否开启调试模式
        autoplay: this.videoAutoplay,// 是否自动播放
        html5m3u8: true,// PC平台上是否使用h5播放器播放m3u8
        live: this.live, // 是否是直播
        poster: this.posterImg, // 封面图片
        video: this.videoSrc, // 视频地址(必填)
      }
    }
  },
  created() {
  },
  mounted() {
    this.instantiateVideo()
  },
  methods: {
    //实例化播放器
    instantiateVideo() {
      if (this.videoSrc) {
        this.player = new ckplayer(this.videoObject)
        if (this.liveAutoplay) {
          setTimeout(() => {
            this.player.videoMute()
            setTimeout(() => {
              this.player.videoPlay()
            }, 2000);
          }, 1000);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>